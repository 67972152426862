import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { FileUploadComponent } from './FileComponent'
import ThumbnailComponent from './ThumbnailComponent'
import ContentComponent from './ContentComponent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { API_BASE_URL } from '../Constants';
import { KeywordComponent } from './KeywordComponent';
import RadioGroupContext from '@mui/material/RadioGroup/RadioGroupContext';
import RadioGroupComponent from './RadioGroupComponent';
import Card from '@mui/material/Card';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export function GeneratorComponent() {

  const initialContent = 'With PikSeeAI, you can easily create compelling product descriptions by uploading images of your products and generating text descriptions based on the content of the images'
  const [getFile, setFile] = useState('camera.png');

  const [fileUploaded, isFileUploaded] = useState(false);
  
  const [getContent, setContent] = useState(initialContent);
  const [getTitle, setTitle] = useState('');
  const [btnGenerate, setBtnGenerate] = useState('Generate');
  const [imageContentType, setImageContentType] = useState(true);
  const [keywords, setKeywords] = useState('');
  const generateContent = async (event: any) => {

    event.preventDefault();
    setTitle('Please wait while we generate content for you!')
    setContent('')
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    var body = {'filename': getFile, 'maxToken': 200 }
    try {
      const response = await fetch(`${API_BASE_URL}/prod/catalog`, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const data = await response.json();
      console.log(data['choices'][0].message.content);
      setTitle('');
      setContent(data['choices'][0].message.content);
      setBtnGenerate('Regenerate')
    } catch (error) {
      console.error(error);
      setTitle('');
      setContent('"Oops! It looks like something went wrong! Our team of friendly bots is working hard to fix this issue. Please try again in a while.');
    }
    
  }

  const generateContentByKeywords = async (event: any) => {

    event.preventDefault();
    setTitle('Please wait while we generate content for you!')
    setContent('')
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    try {
      const response = await fetch(`${API_BASE_URL}/prod/catalog?keywords=${keywords}`, {
        method: 'GET',
        headers
      });
      const data = await response.json();
      console.log(data['choices'][0].message.content);
      setTitle('');
      setContent(data['choices'][0].message.content);
      setBtnGenerate('Regenerate')
    } catch (error) {
      console.error(error);
      setTitle('');
      setContent('"Oops! It looks like something went wrong! Our team of friendly bots is working hard to fix this issue. Please try again in a while.');
    }
    
  }


  const handleFileUpload = (value: string) => {
    setFile(value);
    isFileUploaded(true);
    setBtnGenerate('Generate')
  }

  const handleKeywordAdded = (value: string) => {
    setKeywords(value);
    setBtnGenerate('Generate')
  }

  const handleRedioChange = (value: string) => {
    if(value == 'keyword') {
      setImageContentType(false);
    } else {
      setImageContentType(true);
    }
  }

  const dictionary = {
    'image': 'Description by product image',
    'keyword': 'Description by keywords',
  };
    return (
        
        <Box sx={{ flexGrow: 1 }}>
          <Card style={{margin:'20px', maxWidth:'500px', minWidth:'400px'}}>
            <RadioGroupComponent 
                label=""
                items={dictionary}
                onInputChange={handleRedioChange}
                
              ></RadioGroupComponent>
          </Card>
          
          
          <Grid container spacing={2}>
          
            { imageContentType ? (
            <>
             <Grid item xs={12} md={4}>
              <Item>
                <div style={{margin:'20px'}}>
                <ThumbnailComponent src={ getFile } alt=''></ThumbnailComponent>
                </div>
                
                <div style={{margin:'20px'}}>
                   <Stack spacing={2} direction="row">
                      <FileUploadComponent onInputChange={handleFileUpload}></FileUploadComponent>
                      
                      {fileUploaded &&
                        <Button variant="contained" color='warning' onClick={ generateContent } >{ btnGenerate }</Button>
                      }
                    </Stack>
                </div>
              </Item>
            </Grid>
            </>
             ) : 
            (
              <Grid item xs={12} md={4}>
              <Item>
                <KeywordComponent onInputChange={handleKeywordAdded}></KeywordComponent>
                
                <div style={{margin:'20px'}}>
                   <Stack spacing={2} direction="row">
                        <Button variant="contained" color='warning' 
                        onClick={ generateContentByKeywords } disabled={keywords.length == 0 || keywords.length == 1} >{ btnGenerate }</Button>
                    </Stack>
                </div>
              </Item>
            </Grid>

            )}
           
            <Grid item xs={12} md={8}>
              <Item> <ContentComponent title= { getTitle } content = { getContent }></ContentComponent> </Item>
            </Grid>
           
          </Grid>
        </Box>
      );

}