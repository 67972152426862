import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp';
import LocalSeeSharpIcon from '@mui/icons-material/LocalSeeSharp';
import AutoFixHighSharpIcon from '@mui/icons-material/AutoFixHighSharp';
import InsertEmoticonSharpIcon from '@mui/icons-material/InsertEmoticonSharp';
import { Typography } from '@mui/material';

export default function NestedList() {
  
  return (
    <List
      sx={{ width: '100%',  bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="h5">
          How does it work?
                    </Typography>
            
        </ListSubheader>
      }
    >
      <ListItemButton>
        <ListItemIcon>
          <LocalSeeSharpIcon />
        </ListItemIcon>
        <ListItemText secondary={<Typography>
          To get started, simply click the Upload Image button and select an image of your product or provide keywords
          </Typography>}/>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AutoFixHighSharpIcon />
        </ListItemIcon>
        <ListItemText  primary="Than let our tool run its magic by analyzing the content of the image and generate a description that highlights the key features and benefits of your product" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <InsertEmoticonSharpIcon />
        </ListItemIcon>
        <ListItemText primary="So what are you waiting for? Give our product content generation tool a try today and start creating amazing product descriptions that drive sales and grow your business!" />
      </ListItemButton>
    </List>
  );
}