import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { BUCKET_URL } from '../Constants';

interface ImageThumbnailProps {
  src: string;
  alt: string;
}

const ThumbnailComponent: React.FC<ImageThumbnailProps> = ({ src, alt }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image= {`${BUCKET_URL}/${src}`}
          alt= {alt}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />
        <CardContent>
          <Typography color="text.secondary">
            Upload Image to generate content
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ThumbnailComponent;
