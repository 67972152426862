import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MainNavbar } from './components/NavbarComponent';
import { GeneratorComponent } from './components/GeneratorComponent';
import Footer from './components/FooterComponent';
import Typography from '@mui/material/Typography';
import HowItWorksComponent from './components/HowItWorksComponent';
import IntroComponent from './components/IntroComponent';
function App() {
  return (
    <div className="App">
      <div>
        <MainNavbar></MainNavbar>
      </div>
      
      <div style={{marginTop:'120px'}}>
        <div>
            <IntroComponent></IntroComponent>
        
        </div>
        
        <GeneratorComponent></GeneratorComponent>
        <HowItWorksComponent></HowItWorksComponent>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
