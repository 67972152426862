import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from '../logo.svg';
import Avatar from '@mui/material/Avatar';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#59981A',
      },
    },
  });

export function MainNavbar() {
    return (
        <div>
            <ThemeProvider theme={darkTheme}>
            <AppBar color="primary" >
                <Toolbar>
                    {/* <Avatar alt="Profile Picture" src={logo} /> */}
                    <Typography variant="h4">
                        PikSee AI
                    </Typography>
                      
                </Toolbar>
                
            </AppBar>
            </ThemeProvider>
        </div>
    )

}