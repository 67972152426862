import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { Typography } from '@mui/material';

export default function IntroComponent() {
  
  return (
    <List
      sx={{ width: '100%',  bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="h5">
          Free product content generation tool!
                    </Typography>
            
        </ListSubheader>
      }
    >
    </List>
  );
}