import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { API_BASE_URL } from '../Constants';
import CircularStatic  from './LoadingComponent';

interface FileUploadProps {
  onInputChange: (value: string) => void;
}

export function FileUploadComponent(props: FileUploadProps) {
    const [getFile, setFiles] = useState('camera.png');
    const [uploading, setUploading] = useState(false);

    const handleInputChange = async (event: any) => {
      setUploading(true);
      let fileName: string = uuidv4()+'.jpeg';
    
      const file = await uploadFile(fileName, event.target.files[0]);
      setUploading(false);
      // Call the callback function passed from the parent component
      props.onInputChange(file);
    }
    
    
    useEffect(() => {
        // action on update of movies
    },);
     

    const getBase64 = (file: Blob): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result?.toString().split(',')[1];
          if (base64String) {
            resolve(base64String);
          } else {
            reject('Error: unable to convert file to base64');
          }
        };
        reader.onerror = () => {
          reject(reader.error);
        };
      });
    }

     const uploadFile = async (fileName: string, data: any) => {
      
      const image = await getBase64(data);
      const formData = {
        file: image
      };
    
      try {
        
        const response = await fetch(`${API_BASE_URL}/prod/upload`, {
          method: 'PUT',
          body: JSON.stringify(formData)
        });
    
        if (response.ok) {
          const result = await response.json();
          
          setFiles(result.file_name);
          return result.file_name;
        } else {
          console.log('Request failed.');
          return '';
        }
      } catch (error) {
        console.error(error);
        return '';
      }
    }

    const fileTheme = createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#59981A',
          },
          secondary: {
            main: '#ffffff',
          }
        },
      });
    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <ThemeProvider theme={fileTheme}>
                <Button variant="contained" component="label" color='primary'>
                    Upload
                    <input hidden accept="image/*" multiple type="file"  onChange={handleInputChange}/>
                    <IconButton color="secondary" aria-label="upload picture" component="label">
                    <input hidden accept="image/*" multiple type="file"  onChange={handleInputChange}/>
                    <PhotoCamera />
                </IconButton>
                </Button>
                {uploading && <CircularStatic></CircularStatic>}
            </ThemeProvider>
        </Stack>
        
    )

}