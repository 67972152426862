import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


interface RadioProps {
    label: string;
    items: { [key: string]: string };
    onInputChange: (value: string) => void;
  }

  export default function RowRadioButtonsGroup({label, items, onInputChange }: RadioProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputChange(event.target.value);
    };

    const defaultRadioValue = Object.keys(items)[0]; // Get the first key as the default value

    return (
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">{label} </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleChange}
          defaultValue={defaultRadioValue}
        >
          {Object.entries(items).map(([key, val]) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio color="success" />}
              label={val}
              
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }