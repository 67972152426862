import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { API_BASE_URL } from '../Constants';
import CircularStatic  from './LoadingComponent';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

interface KeywordProps {
  onInputChange: (value: string) => void;
}

export function KeywordComponent(props: KeywordProps) {
    const [keyword, setKeywords] = useState('');

    const handleInputChange = async (event: any) => {
      
      setKeywords(event.target.value);
      // Call the callback function passed from the parent component
      props.onInputChange(keyword);
    }
    
    
    useEffect(() => {
        // action on update of movies
    },);
     

    const customTheme = createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#59981A',
          },
          secondary: {
            main: '#ffffff',
          }
        },
      });
    return (
        
        <FormControl fullWidth sx={{ m: 1 }}>
            <ThemeProvider theme={customTheme}>
                <TextField label="Ex: Gold ring" onChange={handleInputChange} variant="standard" />
            </ThemeProvider>
            <FormHelperText id="outlined-weight-helper-text" >Enter keywords for your product.</FormHelperText>
        </FormControl>
        
    )

}