import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import CircularStatic  from './LoadingComponent';

interface ContentProps {
  title: string;
  content: string;
}

const ContentComponent: React.FC<ContentProps> = ({ title, content }) => {
  return (
    
    <CardContent sx={{ minWidth: 275, minHeight: 267 }}>
        {content == "" && <CircularStatic></CircularStatic>}
        
        <Typography>
            { title }
        </Typography>
        <Typography  component="div">
            { content }
        </Typography>
    </CardContent>
    
  );
};

export default ContentComponent;
