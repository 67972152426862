import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const footerTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#59981A',
      },
    },
  });

export const Footer: FC = (): ReactElement => {
  return (
    <ThemeProvider theme={footerTheme}>
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "primary.main",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="subtitle1">
              {`${new Date().getFullYear()} | Piksee AI`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </Paper>
    </ThemeProvider>
  );
};

export default Footer;